import axios from "axios";

const request = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  timeout: 1000,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

request.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + localStorage.getItem("token");
  return config;
});

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$router.push("Login");
    }
    return Promise.reject(error);
  }
);

export { request };
