import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    isMobile: window.screen.width <= 700,
    duplicationRecord: {},
  },
  getters: {
    getUser: (state) => JSON.parse(JSON.stringify(state.user)),
    getIsMobile: (state) => state.isMobile,
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_IS_MOBILE(state, payload) {
      state.isMobile = payload;
    },
    UPDATE_DUPLICATION_RECORD(state, payload) {
      state.duplicationRecord = payload;
    },
  },
  actions: {
    updateUser({ commit }, data) {
      commit("UPDATE_USER", data);
    },
    updateIsMobile({ commit }, data) {
      commit("UPDATE_IS_MOBILE", data);
    },
    updateDuplicationRecord({ commit }, data) {
      commit("UPDATE_DUPLICATION_RECORD", data);
    },
  },
  modules: {},
});
