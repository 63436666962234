import { createRouter, createWebHistory } from "vue-router";
import Form from "../views/Form.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/form",
    name: "Form",
    component: Form,
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: () => import("../views/Catalog.vue"),
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views/Support.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem("token") && to.path !== "/") {
    router.push("/");
  } else {
    next();
  }
});

export default router;
