<template>
  <div class="login-page">
    <div>
      <span>Document Reference ID Numbering System</span>
      <div class="login-cont">
        <div class="input-cont">
          <input id="email" placeholder="email" />
        </div>
        <div class="input-cont">
          <input id="password" type="password" placeholder="password" />
        </div>
        <div>
          <button class="btn btn-login" @click="login">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/config/axios";

export default {
  methods: {
    login() {
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;

      const data = {
        email: email,
        password: password,
      };

      request
        .post("auth/login", data)
        .then(async (response) => {
          localStorage.setItem("token", response.data.token);
          await this.getUser();
        })
        .then(() => {
          this.$router.push("Form");
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Incorrect email or password",
            life: 3000,
          });
        });
    },
    async getUser() {
      await request
        .get("user")
        .then(async (response) => {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$store.dispatch("updateUser", response.data);
        })
        .then(() => {
          let user = JSON.parse(localStorage.getItem("user")) ?? {};
          console.log("state", this.$store.getters.getUser);
          document.getElementById("lbl-dropdown").innerHTML =
            user.first_name + " " + user.last_name;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style>
.login-page {
  display: flex;
  justify-content: center;
}

.login-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 465px;
  height: 418px;
  left: calc(50% - 465px / 2 - 5.5px);
  top: calc(50% - 418px / 2);
  background: var(--background-color-secondary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.btn-login {
  width: 354px;
  height: 62px;
  left: 537px;
  top: 610px;
  margin: 20px;
  font-size: 18px;
}

.input-cont input {
  width: 67%;
  padding: 20px;
  margin: 20px;
}
</style>
