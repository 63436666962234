<template>
  <div class="form-page">
    <div class='form-cont'>
      <FormHeader :reference_num='reference_num' />
      <hr>
      <FormBulk id='form-bulk' :ref_num='reference_num' />
    </div>
  </div>
</template>

<script>
import FormHeader from "@/components/FormHeader";
import FormBulk from "@/components/FormBulk.vue";
import { request } from "@/config/axios";

export default {
  name: "Form",
  data() {
    return {
      'reference_num': 0
    };
  },
  async beforeCreate() {
    await request
      .get("records")
      .then((response) => {
        console.log(response.data[0]);
        this.reference_num = response.data[0].reference_num + 1;
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  },
  async mounted() {
    document.getElementById('toggle').classList.add('toggle-active-left');
    document.getElementById('toggle').classList.remove('toggle-active-right');
    document.getElementById('toggle-right').style.color = '#888888';
    document.getElementById('toggle-left').style.color = '#FFFFFF';

    document.getElementById('form-single').style.display = 'flex';
    document.getElementById('form-bulk').style.display = 'none';
  },
  components: {
    FormHeader,
    FormBulk
  },
};
</script>

<style>
.form-page {
  display: flex;
  justify-content: center;
}

.form-cont {
  width: 85%;
  left: 118px;
  top: 178px;
  background: var(--background-color-secondary);
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.25);
}

.form-cont hr {
  border: 0.25px solid var(--accent-color);
  width: 95%;
}

.header-input-group input {
  padding: 10px;
}
</style>
