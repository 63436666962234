<template>
  <div class="dropdown">
    <button class="btn-dropdown">
      <span id='lbl-dropdown' class='drop-down-label'></span>

      <i class="arrow arrow-right"></i>
    </button>
    <div class="dropdown-content">
      <a href="/profile">Profile</a>
      <a href="/support">Support</a>
      <a @click='logout'>Logout</a>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    let user = JSON.parse(localStorage.getItem('user')) ?? {};
    document.getElementById('lbl-dropdown').innerHTML = user.first_name + ' ' + user.last_name;
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$router.push('/');
    }
  }
}
</script>
<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--background-color-secondary);
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.dropdown-content a {
  color: #00A4FF;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.dropdown-content a:hover {
  background-color: var(--accent-color);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.btn-dropdown {
  color: #00A4FF;
  background-color: var(--background-color-secondary);
  border: 1px solid #00A4FF;
  border-radius: 5px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
  width: 150px;
  height: 35px;
  cursor: pointer;
}

.drop-down-menu p {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
}

.btn-dropdown:hover > .arrow {
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.btn-dropdown:hover > .arrow-right {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.drop-down-label {
  display: flex;
  font-size: 16px;
  width: 80%;
  justify-content: flex-start;
  height: 30px;
  align-items: center;
}

.arrow {
  border: solid #00A4FF;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
</style>