<template>
  <div class='form-header'>
    <div v-bind:class="{'header-input-group left': !this.$store.getters.getIsMobile, 'header-mobile': this.$store.getters.getIsMobile}">
      <div>
        <input v-model='referenceNum' placeholder='reference #' id='reference_num' disabled />
        <label class='d-flex' for='reference_num'>Reference #</label>
      </div>
      <div>
        <input v-model='date' placeholder='date' id='date' disabled />
        <label class='d-flex' for='date'>Date</label>
      </div>
    </div>
<!--    <div class='header-input-group right' v-if='!this.$store.getters.getIsMobile'>-->
<!--      <div class='toggle toggle-active-left' id='toggle'>-->
<!--        <span @click="toggle('single')" class='toggle-left' id='toggle-left'>Single</span>-->
<!--        <span @click="toggle('bulk')" class='toggle-right' id='toggle-right'>Bulk</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: 'Form',
  props: {
    reference_num: Number
  },
  computed: {
    referenceNum() {
      return this.reference_num;
    }
  },
  data() {
    return {
      date: new Date().toLocaleDateString()
    };
  },
  methods: {
    toggle(event) {
      if (event === 'single') {
        this.toggleSingle();
      } else {
        this.toggleBulk()
      }
    },
    toggleSingle() {
      document.getElementById('toggle').classList.add('toggle-active-left');
      document.getElementById('toggle').classList.remove('toggle-active-right');
      document.getElementById('toggle-right').style.color = '#888888';
      document.getElementById('toggle-left').style.color = '#FFFFFF';

      document.getElementById('form-single').style.display = 'flex';
      document.getElementById('form-bulk').style.display = 'none';
    },
    toggleBulk() {
      document.getElementById('toggle').classList.add('toggle-active-right');
      document.getElementById('toggle').classList.remove('toggle-active-left');
      document.getElementById('toggle-right').style.color = '#FFFFFF';
      document.getElementById('toggle-left').style.color = '#888888';

      document.getElementById('form-single').style.display = 'none';
      document.getElementById('form-bulk').style.display = 'flex';
    }
  }
};
</script>
<style>
.d-flex {
  display: flex;
}

.form-cont hr {
  border: 0.25px solid var(--accent-color);
  width: 95%;
}

.form-header {
  display: flex;
  justify-content: space-between;
}

.header-input-group {
  display: flex;
  width: 33%;
}

.header-mobile {
  display: flex;
  margin: 25px 25px 0px 25px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
}

.header-input-group input {
  padding: 10px;
}

.header-mobile input {
  padding: 10px;
}

.left {
  justify-content: space-between;
  margin: 25px 25px 0px 25px;
  max-width: 30%;
}

.right {
  justify-content: flex-end;
  padding: 25px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  border: 0.5px solid #00A4FF;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-active-left {
  background: linear-gradient(to left, var(--background-color-primary) 50%, #00A4FF 50%);
  color: white;
}

.toggle-active-right {
  background: linear-gradient(to left, #00A4FF 50%, var(--background-color-primary) 50%);
  color: white;
}

.toggle-left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
}
</style>