<template>
  <div class='form-body'>
    <div class='bulk-form'>
      <div class='input-row-bulk'>
        <div>
          <label class='d-flex' for='document-name'>Document Name</label>
          <input v-model=document_name id='document-name' placeholder='Document Name' @input='updatePreview()'/>
        </div>
        <div>
          <label class='d-flex' for='version-id'>Version ID</label>
          <input v-model=version_id id='version-id' placeholder='Version ID' @input='updatePreview()'/>
        </div>
      </div>
      <div class='input-row-bulk'>
        <div>
          <label class='d-flex' for='language'>Language</label>
          <div id='language' class='checkbox-group'>
            <div class='d-flex flex-row' v-for='(item, index) in languageBank' :key='index'>
              <input :id="'chk_language_' + item.value" type='checkbox' :value='item.value' @click='updateLanguage($event)'/>
              <label>{{ item.name }}</label>
            </div>
          </div>
        </div>
        <div>
          <label class='d-flex' for='country'>Country</label>
          <div id='country' class='checkbox-group'>
            <div class='d-flex flex-row' v-for='(item, index) in countryBank' :key='index'>
              <input :id="'chk_country_' + item.value" type='checkbox' :value='item.value' @click='updateCountry($event)'/>
              <label>{{ item.name }}</label>
            </div>
          </div>
        </div>
        <div>
          <label class='d-flex' for='audience'>Audience</label>
          <div id='audience' class='checkbox-group'>
            <div class='d-flex flex-row' v-for='(item, index) in audienceBank' :key='index'>
              <input :id="'chk_audience_' + item.value" type='checkbox' :value='item.value' @click='updateAudience($event)'/>
              <label>{{ item.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-append" @click='append'>+</button>
      </div>
    </div>
    <span class="divider"></span>
    <div class='bulk-preview'>
      <div class='preview-cont-bulk'>
        <div v-for="(item, index) in this.arrDraft" :key="item" class='preview-group'>
          <p class='preview-bulk'>{{ item.file_name }}</p>
          <i class="pi pi-minus-circle" @click='removeFromBulk(index)'/>
          <i class="pi pi-copy copy-icon" @click='copyToClipboard(item)'/>
        </div>
        <div class='preview-group' v-for="item in this.arrPreview" :key="item">
          <p class='preview-bulk unsaved' id='preview-bulk'>{{ item.file_name }}</p>
          <i class="pi pi-copy copy-icon" @click='copyToClipboard(item)'/>
        </div>
      </div>
      <a v-if='this.arrPreview.length > 0' @click='copyAllToClipboard()'>
        Copy All
      </a>
      <div>
        <button class="btn btn-submit-all" @click='addAll'>Add All</button>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/config/axios";

export default {
  props: {
    ref_num: Number
  },
  computed: {
    referenceNum() {
      return this.reference_num;
    },
  },
  watch: {
    ref_num(newVal) {
      this.reference_num = newVal;
    }
  },
  async mounted() {
    await this.initReferenceNumber();

    if (Object.keys(this.$store.state.duplicationRecord).length) {
      await this.initDuplicateRecord();
    }
  },
  data() {
    return this.init()
  },
  methods: {
    init() {
      return {
        date: new Date()
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replaceAll("/", ""),
        reference_num: this.ref_num || null,
        audience: '',
        document_name: '',
        document_type: '',
        project_id: '',
        version_id: '',
        vertical: '',
        language: '',
        arrSave: [],
        arrDraft: [],
        arrPreview: [],
        audiences: [],
        countries: [],
        languages: [],
        audienceBank: [
          { name: 'Intermedia (DIR)', value: 'DIR' },
          { name: 'Cobrand (CBRND)', value: 'CBRND' },
          { name: 'White Label (PLR)', value: 'PLR' },
          { name: 'To Partners (ToPLR)', value: 'ToPLR' },
          { name: 'Advisor (Advisor)', value: 'Advisor' },
          { name: 'Corp (CORP)', value: 'CORP' },
          { name: 'Service Providers (SP)', value: 'SP' },
          { name: 'Elevate (ELVT)', value: 'ELVT' },
          { name: 'Ascend (ASCND)', value: 'ASCND' },
          { name: 'BYOC (BYOC)', value: 'BYOC' },
        ],
        docTypeBank: [
          { name: 'Flyer (Fly)', value: 'Fly' },
          { name: 'Brochure (Broch)', value: 'Broch' },
          { name: 'Casestudy (CS)', value: 'CS' },
          { name: 'Datasheet (DATA)', value: 'DATA' },
          { name: 'Infographic (InfoGph)', value: 'InfoGph' },
          { name: 'Instructional Guide (Inst)', value: 'Inst' },
          { name: 'Presentation (PPT)', value: 'PPT' },
          { name: 'Promo (Promo)', value: 'Promo' },
          { name: 'Report/EBook (Rpt)', value: 'Rpt' },
          { name: 'Sales Tool (ST)', value: 'ST' },
          { name: 'Ordered List (OList)', value: 'OList' },
          { name: 'White Paper (WP)', value: 'WP' },
          { name: 'Postcard (PostCd)', value: 'PostCd' },
          { name: 'Comparison Guide (CompGuide)', value: 'CompGuide' },
          { name: 'Slick (Slick)', value: 'Slick' },
          { name: 'Newsletter (News)', value: 'News' },
          { name: 'Blog (Blog)', value: 'Blog' },
        ],
        verticalBank: [
          { name: 'Financial Services (FS)', value: 'FS' },
          { name: 'Healthcare (HC)', value: 'HC' },
          { name: 'High-tech (HT)', value: 'HT' },
          { name: 'Legal (L)', value: 'L' },
          { name: 'Manufacturing (M)', value: 'M' },
          { name: 'Architecture, Engineering, Construction (AEC)', value: 'AEC' },
        ],
        languageBank: [
          { name: 'English (EN)', value: 'EN' },
          { name: 'French (FR)', value: 'FR' },
          { name: 'Dutch (NL)', value: 'NL' },
          { name: 'German (DE)', value: 'DE' },
          { name: 'Italian (IT)', value: 'IT' },
          { name: 'Russian (RU)', value: 'RU' },
          { name: 'Spanish (ES)', value: 'ES' },
          { name: 'Canadian French (FRCA)', value: 'FRCA' },
        ],
        countryBank: [
          { name: 'Belgium (BE)', value: 'BE' },
          { name: 'Canada (CA)', value: 'CA' },
          { name: 'France (FR)', value: 'FR' },
          { name: 'Germany (DE)', value: 'DE' },
          { name: 'Great Britain (GB)', value: 'GB' },
          { name: 'Ireland (IE)', value: 'IE' },
          { name: 'Italy (IT)', value: 'IT' },
          { name: 'Japan (JP)', value: 'JP' },
          { name: 'Mexico (MX)', value: 'MX' },
          { name: 'Netherlands (NL)', value: 'NL' },
          { name: 'Portugal (PT)', value: 'PT' },
          { name: 'United States (US)', value: 'US' },
        ]
      };
    },
    async initReferenceNumber() {
      if (this.ref_num && this.ref_num > 0) {
        this.reference_num = this.ref_num;
      } else {
        await request
          .get("records")
          .then((response) => {
            this.reference_num = parseInt(response.data[0].reference_num) + 1;
          })
          .catch((error) => {
            console.log(error.response.data.error);
          });
      }
    },
    async append() {
      for (let i = 0; i < this.languages.length; i++) {
        for (let j = 0; j < this.countries.length; j++) {
          for (let k = 0; k < this.audiences.length; k++) {
            let data = {
              file_name: this.getFileName(this.audiences[k], this.languages[i], this.countries[j]),
              reference_num: this.reference_num,
              doc_name: this.document_name,
              doc_type: this.document_type,
              version_id: 'V' + this.version_id,
              project_id: this.project_id,
              audience: this.audiences[k],
              vertical: this.vertical,
              language: this.languages[i],
              country: this.countries[j],
            };
            this.arrSave.push(data);
          }
        }
      }
      this.arrDraft.push(...this.arrPreview);
      this.arrPreview = [];
    },
    async addAll() {
      await request.post('/records', this.arrSave)
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: this.arrSave.length + " Records saved",
            life: 3000,
          });

          this.$router.push("/catalog");
          this.resetState();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Failed to save records",
            life: 3000,
          });
        });
    },
    resetState() {
      this.date = new Date()
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", ""),
      this.audience = '';
      this.document_name = '';
      this.document_type = '';
      this.project_id = '';
      this.version_id = '';
      this.vertical = '';
      this.language = '';
      this.country = '';
      this.arrSave = [];
      this.arrDraft = [];
    },
    copyToClipboard(item = null) {
      if (item) {
        navigator.clipboard.writeText(item.file_name);
      } else {
        navigator.clipboard.writeText(
          document.getElementById("preview-bulk").innerHTML
        );
      }

      this.$toast.add({
        severity: "success",
        summary: "Copied!",
        detail: "Copied to clipboard!",
        life: 3000,
      });
    },
    copyAllToClipboard() {
      let clipboard = '';
      for (let i = 0; i < this.arrPreview.length; i++) {
        clipboard = clipboard + ' ' + this.arrPreview[i].file_name;
      }

      navigator.clipboard.writeText(clipboard);

      this.$toast.add({
        severity: "success",
        summary: "Copied!",
        detail: "Copied to clipboard!",
        life: 3000,
      });
    },
    removeFromBulk(index) {
      this.arrDraft.splice(index, 1)
      this.arrSave.splice(index, 1)
    },
    async updateAudience(event) {
      if (event.target.checked) {
        this.audiences.push(event.target.value);
      } else {
        this.audiences = this.audiences.filter(
          (audience) => audience !== event.target.value
        );
      }

      this.updatePreview();
    },
    async updateLanguage(event) {
      if (event.target.checked) {
        this.languages.push(event.target.value);
      } else {
        this.languages = this.languages.filter(
            (language) => language !== event.target.value
        );
      }

      this.languages = this.languages.filter(x => x);

      this.updatePreview();
    },
    async updateCountry(event) {
      if (event.target.checked) {
        this.countries.push(event.target.value);
      } else {
        this.countries = this.countries.filter(
            (country) => country !== event.target.value
        );
      }

      this.countries = this.countries.filter(x => x);

      this.updatePreview();
    },
    updatePreview() {
      this.arrPreview = [];

      this.handleMultiSelects();

      return this.arrPreview;
    },
    handleMultiSelects() {
      for (let i = 0; i < this.languages.length; i++) {
        for (let j = 0; j < this.countries.length; j++) {
          for (let k = 0; k < this.audiences.length; k++) {
            this.pushPreview(
              this.audiences[k],
              this.languages[i],
              this.countries[j]
            );
          }
        }
      }
    },
    getFileName(audience = '', language = '', country = '') {
      const preview = this.reference_num + '-V' + this.version_id + '_' + audience + '_' + this.document_name + '_' + language + '_' + country + '_' + this.date;

      return preview.toString();
    },
    pushPreview(audience = '', language = '', country = '') {
      this.arrPreview.push({
        file_name: this.getFileName(audience, language, country),
        reference_num: this.reference_num,
        doc_name: this.document_name,
        version_id: 'V' + this.version_id,
        audience: audience,
        language: this.language,
        country: this.country,
      });
    },
    initDuplicateRecord() {
      const stateData = this.$store.state.duplicationRecord;
      this.reference_num = stateData.reference_num;
      this.audience = stateData.audience;
      this.document_name = stateData.doc_name;
      this.document_type = stateData.doc_type;
      this.project_id = stateData.project_id;
      this.version_id = stateData.version_id;
      if (stateData.version_id.length > 0 && stateData.version_id[0] === 'V') {
        this.version_id = stateData.version_id.substring(1);
      }
      this.vertical = stateData.vertical;
      this.audiences.push(stateData.audience);
      this.countries.push(stateData.country);
      this.languages.push(stateData.language);
      document.getElementById('chk_audience_' + stateData.audience).checked = 'checked';
      document.getElementById('chk_language_' + stateData.language).checked = 'checked';
      document.getElementById('chk_country_' + stateData.country).checked = 'checked';
      this.pushPreview(
        stateData.audience,
        stateData.language,
        stateData.country
      );
    }
  }
};
</script>

<style scoped>
.form-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn:hover {
  color: #00A4FF;
  background-color: var(--background-color-primary);
  border: 1px solid #00A4FF;
  cursor: pointer;
}

.btn:active {
  border: 1px solid #00A4FF;
  background-color: #00A4FF;
  color: white;
}

.divider {
  display: inline-block;
  border-left: 1px solid var(--accent-color);
  height: 63vh;
}

.bulk-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.input-row-bulk {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: flex-start;
}

.preview-cont-bulk {
  margin: 5px;
  padding: 5px;
  overflow-y: auto;
}

.bulk-preview {
  display: flex;
  width: 50%;
  height: 430px;
  flex-direction: column;
  justify-content: space-between;
}

.preview-bulk {
  font-size: 18px;
}

.btn-append {
  width: 240px;
  height: 39px;
  left: 537px;
  top: 610px;
  margin: 20px;
  font-size: 24px;
}

select:focus {
  outline: none !important;
  border-color: #00A4FF;
}

.btn-submit-all {
  width: 240px;
  height: 39px;
  top: 610px;
  font-size: 18px;
}

input {
  border-radius: 5px;
  color: #888888;
}

input:focus {
  outline: none !important;
  border-color: #00A4FF;
}

select {
  border-radius: 5px;
  color: #888888;
}

.form-cont hr {
  border: 0.25px solid var(--accent-color);
  width: 95%;
}

.header-input-group input {
  padding: 10px;
}

.input-row-bulk select {
  padding: 10px;
  margin: 5px;
  width: 240px;
}

.input-row-bulk input:not([type=checkbox]) {
  padding: 10px;
  margin: 5px;
  width: 240px;
}

.input-row-bulk label {
  margin-left: 10px;
}

.unsaved {
  color: #00a4ff;
}

.preview-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-group .pi-minus-circle {
  color: red;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.checkbox-group {
  height: 180px;
  overflow-y: auto;
  width: 180px;
}
</style>
