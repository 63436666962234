<template>
  <div id="nav">
    <div class="logo">
      <img id='logo-light' alt="Intermedia logo" src="@/assets/intm_logo.png" />
      <img id='logo-dark' alt="Intermedia logo" src="@/assets/intm_logo_dark.png" />
    </div>
    <div id='nav-desktop' class='nav-desktop' v-if='!this.$store.getters.getIsMobile'>
      <div v-if="!$route.meta.hideNavbar" class="nav-links">
        <router-link to="/form">Form</router-link>
        <span class="nav-delim">|</span>
        <router-link to="/catalog">Catalog</router-link>
      </div>
      <div v-if="!$route.meta.hideNavbar" class="profile">
        <DropDown />
      </div>
    </div>
    <div id='nav-mobile' v-if='this.$store.getters.getIsMobile && !$route.meta.hideNavbar'>
      <Button type="button" @click="toggle"><i class="pi-bars"></i></Button>
      <Menu ref="menu" :model="items" :popup="true" />
    </div>
  </div>

  <router-view />
  <Toast position="bottom-right" />
  <div class="footer">
    <span
      >Copyright © Intermedia.net, Inc. 1995 — 2022. All Rights Reserved.</span
    >
  </div>
</template>
<script>
import DropDown from "@/components/DropDown.vue";
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import Button from "primevue/button";

export default {
  components: {
    DropDown,
    Menu,
    Toast,
    Button
  },
  mounted() {
    const activeTheme = localStorage.getItem("user-theme");

    document.documentElement.className = activeTheme ?? 'light-theme';
    if (activeTheme === "light-theme") {
      document.getElementById('logo-dark').style.display = 'none';
      document.getElementById('logo-light').style.display = 'block';
      document.getElementById('logo-light').style.width = '200px';
    } else {
      document.getElementById('logo-dark').style.display = 'block';
      document.getElementById('logo-light').style.display = 'none';
      document.getElementById('logo-dark').style.width = '250px';
    }
  },
  created() {
    window.addEventListener("resize", this.adjustScreen);
  },
  unmounted() {
    window.removeEventListener("resize", this.adjustScreen);
  },
  data() {
    return {
      items: [
        {
          label: 'Form',
          icon: 'pi-list',
          command: () => {
            this.$router.push('Form')
          }
        },
        {
          label: 'Catalog',
          icon: 'pi-table',
          command: () => {
            this.$router.push('Catalog')
          }
        },
        {
          label: 'Profile',
          icon: 'pi-user',
          command: () => {
            this.$router.push('Profile')
          }
        },
        {
          label: 'Support',
          icon: 'pi-question',
          command: () => {
            this.$router.push('Support')
          }
        },
        {
          label: 'Logout',
          icon: 'pi-times',
          command: () => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.$router.push('Login');
          }
        }
      ]
    }
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    adjustScreen(e) {
      const screen = e.target.screen;

      this.$store.dispatch("updateIsMobile", (screen.width <= 700));
    },
  }
};
</script>
<style>
body {
  background-color: var(--background-color-primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --background-color-primary: #FFFFFF;
  --background-color-secondary: #FFFFFF;
  --accent-color: #d7d7d7;
  --text-primary-color: #888888;
  --element-size: 4rem;
  --border: 1px solid #00A4FF;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #202124;
  --background-color-secondary: #2d2d30;
  --accent-color: #202124;
  --text-primary-color: #b4b4b4;
  --border: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-primary-color);
}

input, select, textarea {
  background: var(--background-color-primary);
  border: var(--border);
}

#nav {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.nav-desktop {
  display: flex;
  justify-content: space-between;
  width: 57%;
}

#nav a.router-link-exact-active {
  color: #00a4ff;
}

.profile {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.nav-delim {
  padding: 10px;
  font-size: 24px;
}

.logo {
  height: 100px;
}

.logo img {
  width: 250px;
}

.nav-links a {
  font-size: 24px;
  text-decoration: none;
  color: var(--text-primary-color);
}

.footer {
  margin: 30px;
}

.btn {
  color: white;
  background-color: #00a4ff;
  border: 1px solid #00a4ff;
  border-radius: 5px;
  font-weight: 100;
}

.btn:hover {
  color: #00A4FF;
  background-color: var(--background-color-primary);
  border: 1px solid #00A4FF;
  cursor: pointer;
}

.btn:active {
  border: 1px solid #00a4ff;
  color: #00a4ff;
  background-color: white;
}

.justify-center {
  justify-content: center;
}

.flex-direction-column {
  flex-direction: column;
}
</style>
